import React from 'react';

import EditIcon from '../../assets/images/edit.svg';
import styles from './CustomTable.module.scss';
import Loader from '../loader/Loader';
import DeleteIcon from '../../assets/images/bin.svg';
import ViewIcon from '../../assets/images/showPass.svg';
import DownloadIcon from '../../assets/images/download.svg';
import SuccessIcon from '../../assets/images/success-green.svg';
import ErrorIcon from '../../assets/images/Failed.svg';
import APPCONSTANTS from '../../constants/appConstants';
import Pagination from '../Pagination';
import CustomTooltip from '../tooltip';
export interface IAnyObject {
  [key: string]: any;
}
export interface ICustomProps {
  title: string | ((data: any) => string);
  icon: string | ((data: any) => string);
  style: any;
  isSendButtonEnabled?: any;
  isMOHReport?: any;
  onClick?: (event: any, data: any, index: any) => void;
  tooltipFormatter?: (data: any) => string;
  iconFormatter?: any;
  type?: (data: any) => string;
}
interface ICustomTableProps {
  handlePageChange?: (pageNo: number, rowsPerPage?: number) => void;
  columnsDef: IColumns[];
  rowData: any;
  isEdit: boolean;
  isDelete: boolean;
  isView: boolean;
  isDownload: boolean;
  page?: number;
  count?: number;
  isRowEdit?: boolean;
  isStatus?: boolean;
  onRowEdit?: (data: any) => void;
  onRowView?: (data: any) => void;
  onRowDownload?: (data: any) => void;
  confirmationTitle?: string;
  loading?: boolean;
  onDeleteClick?: (deleteData: { data: any; index: number; pageNo: number }) => void;
  className?: string;
  isSearching?: boolean;
  noDataText?: string;
  orderBy?: string;
  order?: number;
  rowsPerPage?: number;
  deleteTitle?: string;
  handleRowClick?: (data: IAnyObject) => void;
  isCustom?: boolean;
  customProps?: ICustomProps[];
  customStatusProps?: ICustomProps[];
}

export interface IColumns {
  id: number;
  name: string;
  label: string;
  width?: string;
  cellFormatter?: (data: any, column: IColumns) => void;
  disableSort?: boolean;
  align?: 'center' | 'left';
  class?: string;
}

interface ICustomTableState {
  openDialog: boolean;
}

export default class CustomTable extends React.PureComponent<ICustomTableProps, ICustomTableState> {
  currentDeleteObj: any = {};
  currentEditObj = {};
  tableRef: React.RefObject<HTMLInputElement>;
  constructor(props: ICustomTableProps) {
    super(props);
    this.tableRef = React.createRef();
    this.state = {
      openDialog: false
    };
  }

  /**
   * Handle pagination change of custom table
   * @param e Mouse event for onchange
   * @param pageNo Current page number
   * @param rowsPerPage Row count per page
   */
  handlePageChange = (pageNo: number, rowsPerPage: number) => {
    if (this.tableRef && this.tableRef.current) {
      this.tableRef.current.scrollTo(0, 0);
    }
    if (this.props.handlePageChange) {
      this.props.handlePageChange(pageNo, rowsPerPage);
    }
  };

  /**
   * Handle delete of custom table
   * @param data Data to delete
   * @param index Index of data
   * @param pageNo Current page number
   */
  handleDelete = (
    e: React.MouseEvent<HTMLSpanElement, MouseEvent>,
    data: IAnyObject,
    index: number,
    pageNo?: number | undefined
  ) => {
    e.stopPropagation();
    const { rowData } = this.props;
    if (rowData.length === 1 && pageNo) {
      this.currentDeleteObj = { data, index, pageNo: pageNo - 1 };
    } else {
      this.currentDeleteObj = { data, index, pageNo };
    }
    this.setState({ openDialog: true });
  };

  handleEdit = (e: React.MouseEvent<HTMLSpanElement, MouseEvent>, data: any, index: number) => {
    e.stopPropagation();
    if (this.props.onRowEdit) {
      this.props.onRowEdit({ ...data, index });
    }
  };

  handleView = (e: React.MouseEvent<HTMLSpanElement, MouseEvent>, data: any, index: number) => {
    e.stopPropagation();
    if (this.props.onRowView) {
      this.props.onRowView({ ...data, index });
    }
  };

  handleDownload = (e: React.MouseEvent<HTMLSpanElement, MouseEvent>, data: any, index: number) => {
    e.stopPropagation();
    if (this.props.onRowDownload) {
      this.props.onRowDownload({ ...data, index });
    }
  };

  /**
   * Handles cancel event of confirmation dialog
   */
  handleConfirmationClose = () => {
    this.currentDeleteObj = {};
    this.setState({ openDialog: false });
  };

  /**
   * Handles proceed event of confirmation dialog
   */
  handleConfirmationSuccess = () => {
    this.setState({ openDialog: false });
    if (this.props.onDeleteClick) {
      this.props.onDeleteClick(this.currentDeleteObj);
    }
  };

  handleChangeRowsPerPage = (rowsPerPage: number) => {
    if (this.tableRef && this.tableRef.current) {
      this.tableRef.current.scrollTo(0, 0);
    }
    if (this.props.handlePageChange) {
      this.props.handlePageChange(1, Number(rowsPerPage));
    }
  };

  navigateToDetail = (data: IAnyObject) => {
    if (this.props.handleRowClick) {
      this.props.handleRowClick(data);
    }
  };

  render() {
    const {
      columnsDef,
      rowData,
      isEdit,
      isDelete,
      isStatus,
      isView,
      isDownload,
      loading,
      page,
      rowsPerPage,
      count,
      isRowEdit,
      handleRowClick,
      isCustom = false,
      customProps = [
        {
          title: '',
          icon: '',
          style: {},
          onClick: () => {},
          tooltipFormatter: () => '',
          iconFormatter:  () => {},
          isMOHReport: false,
          isSendButtonEnabled: false,
          type: () => {}
        }
      ],
      customStatusProps = [
        {
          title: '',
          icon: '',
          style: {},
          onClick: () => {},
          tooltipFormatter: () => '',
          iconFormatter:  () => {},
          isMOHReport: false,
          isSendButtonEnabled: false,
          type: () => {}
        }
      ]
    } = this.props;
    const actions = isEdit || isDelete || isView || isDownload || isCustom;
    return (
      <div className={styles.customTable}>
        <table>
          {
            <thead>
              <tr>
                {columnsDef &&
                  columnsDef.map((column: IColumns, index: number) => (
                    <th key={index} style={{ width: column.width }}>
                      {column.label}
                    </th>
                  ))}
                {actions && (
                  <th className='text-center' style={{ width: '140px' }}>
                    Actions
                  </th>
                )}
                {isStatus && (
                  <th className='text-center' style={{ width: '100px' }}>
                    Status
                  </th>
                )}
              </tr>
            </thead>
          }
          <tbody className={handleRowClick || isRowEdit ? styles.pointer : ''}>
            {rowData.length && !loading ? (
              <>
                {rowData.map((data: IAnyObject, idx: number) => (
                  <tr key={idx} onClick={() => this.navigateToDetail(data)}>
                    {columnsDef &&
                      columnsDef.map((column: IColumns) => (
                        <td key={column.id} className={column.class ? 'numeric' : ''}>
                          {column.cellFormatter ? column.cellFormatter(data, column) : data[column.name]}
                        </td>
                      ))}
                    {actions && (
                      <td key={idx} className='text-center'>
                        <div className='d-inline-flex'>
                          {isEdit && (
                            <div className={styles.editIcon} onClick={(e) => this.handleEdit(e, data, idx)}>
                              <CustomTooltip title='Edit'>
                                <img src={EditIcon} alt='edit-icon' />
                              </CustomTooltip>
                            </div>
                          )}
                          {isDelete && (
                            <div className={styles.deleteIcon} onClick={(e) => this.handleDelete(e, data, idx)}>
                              <CustomTooltip title='Delete'>
                                <img src={DeleteIcon} alt='delete-icon' />
                              </CustomTooltip>
                            </div>
                          )}
                          {isView && (
                            <div className={styles.viewIcon} onClick={(e) => this.handleView(e, data, idx)}>
                              <CustomTooltip title='View'>
                                <img src={ViewIcon} alt='view-icon' />
                              </CustomTooltip>
                            </div>
                          )}
                          {isDownload && (
                            <div className={styles.downloadIcon} onClick={(e) => this.handleDownload(e, data, idx)}>
                              <CustomTooltip title='Download'>
                                <img src={DownloadIcon} alt='download-icon' />
                              </CustomTooltip>
                            </div>
                          )}
                          {isCustom && customProps.map((customProp, idx) => (
                              <div key={idx} className={`${customProp.isMOHReport? ((customProp.isSendButtonEnabled(data))
                                  ? styles.customIcon
                                  : styles.customNoHoverIcon)
                                : (customProp.onClick && styles.customIcon)} ${customProp?.type && customProp?.type(data) === 'success' && styles.customSuccessIcon}`} onClick={(e) => customProp?.onClick && customProp?.onClick(e, data, idx)}
                              >
                                <CustomTooltip
                                  title={
                                    (customProp?.tooltipFormatter &&
                                      customProp?.tooltipFormatter(data)) ||
                                      (typeof customProp?.title === 'string' ? customProp?.title : customProp?.title(data))
                                  }
                                >
                                 <img src={customProp?.iconFormatter ? customProp?.iconFormatter(data)
                                   : (typeof customProp?.icon === 'string' ? customProp?.icon : customProp?.icon(data))} style={typeof customProp?.style === 'object'
                                    ? customProp?.style : customProp?.style(data)} alt='custom-icon' />
                                </CustomTooltip>
                              </div>
                            ))
                          }
                        </div>
                      </td>
                    )}
                    {isStatus &&  (
                      <td className='text-center px-2'>
                        <div className='d-flex justify-content-between align-items-center'>
                        {isCustom && customStatusProps.map((customProp, idx) => (
                              <div key={idx} className= {
                                  customProp.onClick ? styles.customIcon : styles.customStatusIcon
                                }
                                onClick={(e) =>
                                  customProp?.onClick && customProp?.onClick(e, data, idx)
                                }
                              >
                                <CustomTooltip
                                  title={
                                    (customProp?.tooltipFormatter &&
                                      customProp?.tooltipFormatter(data)) ||
                                      (typeof customProp?.title === 'string' ? customProp?.title : customProp?.title(data))
                                  }
                                >
                                  <img
                                    src={
                                      (customProp?.iconFormatter &&
                                        (customProp?.iconFormatter(data) === APPCONSTANTS.SUCCESS.toUpperCase() || 
                                        customProp?.iconFormatter(data) === APPCONSTANTS.ERROR.toUpperCase() || customProp?.iconFormatter(data) === APPCONSTANTS.WARNING.toUpperCase()) &&
                                        (customProp?.iconFormatter(data) === APPCONSTANTS.SUCCESS.toUpperCase() ? SuccessIcon : ErrorIcon)) ||
                                        customProp?.icon
                                    }
                                    style={typeof customProp?.style === 'object'
                                    ? customProp?.style : customProp?.style(data)}
                                    alt='custom-icon'
                                  />
                                </CustomTooltip>
                              </div>
                            ))
                          }
                        </div>
                      </td>
                    )}
                  </tr>
                ))}
              </>
            ) : (
              <tr className='cursor-default'>
                <td colSpan={actions ? columnsDef.length + 1 : columnsDef.length}>
                  {loading && <Loader isFulllScreen={false} />}
                  {!loading && <div className='text-center'>{APPCONSTANTS.NO_RECORDS_FOUND}</div>}
                </td>
              </tr>
            )}
          </tbody>
        </table>
        {page && rowsPerPage && count && count > APPCONSTANTS.ROWS_PER_PAGE_OF_TABLE ? (
          <div className={styles.paginationWrapper}>
            <Pagination
              initialPage={page}
              total={count}
              length={rowsPerPage}
              currentPage={page}
              onChangePage={this.handlePageChange}
              onChangeRowsPerPage={this.handleChangeRowsPerPage}
            />
          </div>
        ) : null}
      </div>
    );
  }
}
