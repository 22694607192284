import APPCONSTANTS from '../../constants/appConstants';
import sessionStorageServices from '../../global/sessionStorageServices';
import * as USERTYPES from './actionTypes';

import { UserActions, IUserState, IUser } from './types';

const userInitialStateGetter = (): IUser => ({
  email: '',
  username: '',
  firstName: '',
  lastName: '',
  id: 0,
  role: '',
  tenantId: sessionStorageServices.getItem(APPCONSTANTS.TENANT_ID) || '',
  country: ''
});

// This should be function instead of object,
// so that the isLoggedIn will be recomputed when RESET_STATE action is dispatched

const initialStateGetter: () => IUserState = () => ({
  defaultRole: [],
  isLoggedIn: Boolean(sessionStorageServices.getItem(APPCONSTANTS.AUTHTOKEN)),
  loggingIn: false,
  loggingOut: false,
  user: userInitialStateGetter(),
  error: null,
  loading: false,
  initializing: false,
  isPasswordSet: false,
  email: '',
  timezoneList: [],
  errorMessage: '',
  countryName: sessionStorageServices.getItem(APPCONSTANTS.COUNTRY_NAME) || '',
  username: '',
  isKenya: false
});

const userReducer = (state = initialStateGetter(), action: UserActions): IUserState => {
  switch (action.type) {
    case USERTYPES.LOGIN_REQUEST:
      return {
        ...state,
        loggingIn: true
      };
    case USERTYPES.LOGIN_SUCCESS:
      return {
        ...state,
        loggingIn: false,
        isLoggedIn: true,
        user: action.payload,
        error: null
      };
    case USERTYPES.LOGIN_FAILURE:
      return {
        ...state,
        loggingIn: false,
        isLoggedIn: false,
        user: userInitialStateGetter(),
        error: action.payload.error
      };
    case USERTYPES.LOGOUT_REQUEST:
      return {
        ...state,
        loggingOut: true
      };
    case USERTYPES.LOGOUT_SUCCESS:
      return {
        ...state,
        isLoggedIn: false,
        loggingOut: false
      };
    case USERTYPES.LOGOUT_FAILURE:
      return {
        ...state,
        isLoggedIn: false,
        loggingOut: false
      };
    case USERTYPES.FETCH_TIMEZONE_LIST_REQUEST:
      return {
        ...state,
        loading: true
      };
    case USERTYPES.FETCH_TIMEZONE_LIST_SUCCESS:
      return {
        ...state,
        timezoneList: action.payload,
        loading: false
      };
    case USERTYPES.FETCH_TIMEZONE_LIST_FAILURE:
      return {
        ...state,
        loading: false
      };
    case USERTYPES.FETCH_LOGGED_IN_USER_REQUEST:
      return {
        ...state,
        initializing: true
      };
    case USERTYPES.USER_FORGOT_PASSWORD_REQUEST:
    case USERTYPES.RESET_PASSWORD_REQUEST:
    case USERTYPES.GET_USERNAME_FOR_PASSWORD_RESET:
    case USERTYPES.CREATE_PASSWORD_REQUEST:
      return {
        ...state,
        loading: true
      };
    case USERTYPES.FETCH_LOGGED_IN_USER_FAILURE:
      return {
        ...state,
        initializing: false
      };
    case USERTYPES.USER_FORGOT_PASSWORD_SUCCESS:
    case USERTYPES.USER_FORGOT_PASSWORD_FAIL:
    case USERTYPES.RESET_PASSWORD_FAIL:
    case USERTYPES.RESET_PASSWORD_SUCCESS:
    case USERTYPES.CREATE_PASSWORD_SUCCESS:
    case USERTYPES.CREATE_PASSWORD_FAIL:
    case USERTYPES.GET_USERNAME_FOR_PASSWORD_RESET_FAIL:
      return {
        ...state,
        loading: false
      };
    case USERTYPES.GET_USERNAME_FOR_PASSWORD_RESET_SUCCESS:
      return {
        ...state,
        loading: false,
        email: action.response.email,
        isPasswordSet: action.response.isPasswordSet,
        username: action.response.username
      };
    case USERTYPES.FETCH_LOGGED_IN_USER_SUCCESS:
      return {
        ...state,
        initializing: false,
        user: action.payload
      };
    case USERTYPES.SESSION_TIMEDOUT:
      sessionStorageServices.clearAllItem();
      return {
        ...state,
        isLoggedIn: false,
        errorMessage: action.message
      };
      case USERTYPES.FETCH_COUNTRY_NAME:
        return{
          ...state,
        countryName: sessionStorageServices.getItem(APPCONSTANTS.COUNTRY_NAME) || ''};
      case USERTYPES.FETCH_IS_KENYA:
        return{
          ...state,
        isKenya: sessionStorageServices.getItem(APPCONSTANTS.COUNTRY_NAME) === APPCONSTANTS.KENYA
        || sessionStorageServices.getItem(APPCONSTANTS.REGION_ID) === APPCONSTANTS.KENYA_ID};
      case USERTYPES.CLEAR_IS_KENYA:
        return{
          ...state,
          isKenya: false
        };
      case USERTYPES.CLEAR_COUNTRY_NAME:
        return{
          ...state,
        countryName: ''
        };
      case USERTYPES.CLEAR_COUNTRY_ID:
        return {
          ...state,
          user: {...state.user, country: ''}
        };
    default:
      return {
        ...state
      };
  }
};

export default userReducer;
