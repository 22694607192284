import axios, { AxiosResponse } from 'axios';

import ApiError from './ApiError';
import ERRORS from '../constants/error';
import APPCONSTANTS from '../constants/appConstants';
import sessionStorageServices from './sessionStorageServices';
import { fetchLoggedInUser, resetStore, sessionTimedout } from '../store/user/actions';
import toastCenter from '../utils/toastCenter';

export const setupInterceptors = (store: any) => {
  axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;
  axios.defaults.headers.post['Content-Type'] = 'application/json';
  axios.defaults.validateStatus = () => true;

  axios.interceptors.request.use(
    (request: any) => {
      const token = sessionStorageServices.getItem(APPCONSTANTS.AUTHTOKEN);
      request.headers.Authorization = token;
      return request;
    },
    (error: any) => Promise.reject(error)
  );

  axios.interceptors.response.use(
    (response: AxiosResponse) => {
      const { status } = response;
      if (status > 205 && status !== 201) {
        switch (status) {
          case 500:
          case 502:
            throw new ApiError(ERRORS.SERVER_ERROR);
          case 403:
            throw new ApiError(ERRORS.UNAUTHORIZED);
          case 401:
            if (response.config.url === 'auth-service/session') {
              throw new ApiError(ERRORS.NOT_AUTHENTICATED);
            } else if (response.config.url === '/report/send-to-KHIS') {
              throw new ApiError(ERRORS.KHIS_NOT_AUTHENTICATED);
            }
              else {
              store.dispatch(sessionTimedout(response.data.message || APPCONSTANTS.SESSION_EXPIRED));
              store.dispatch(resetStore());
              toastCenter.error(APPCONSTANTS.ERROR, APPCONSTANTS.SESSION_EXPIRED, { animateIfActive: false });
              throw new ApiError(ERRORS.NOT_AUTHENTICATED);
            }
          case 409:
            throw new ApiError(response.data);
          case 406:
            throw new ApiError(response.data);
          case 400:
            throw new ApiError(response.data);
          case 432:
            throw new ApiError(response.data);
          case 408:
            throw new ApiError(response.data);
          case 404:
            throw new ApiError(response.data);
          default:
            throw new ApiError(response);
        }
      } else {
        return response;
      }
    },
    () => {
      throw new ApiError(ERRORS.NETWORK_ERROR);
    }
  );

  // get logged in user while refresh
  if (sessionStorageServices.getItem(APPCONSTANTS.AUTHTOKEN)) {
    store.dispatch(fetchLoggedInUser());
  }
};
