import React, { useMemo } from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import './Home.scss';
import ReportsLogo from '../../assets/images/reports.svg';
// import InsightsLogo from '../../assets/images/insights.svg';
import { PROTECTED_ROUTES } from '../../constants/route';
import { roleSelector, rolesSelector } from '../../store/user/selectors';
import sessionStorageServices from '../../global/sessionStorageServices';
import APPCONSTANTS from '../../constants/appConstants';
import { clearIsKenya, fetchIsKenya } from '../../store/user/actions';

const Home = (): React.ReactElement => {
  const dispatch = useDispatch();
  const role = useSelector(roleSelector);
  const roles = useSelector(rolesSelector);
  const rolesValue = useMemo(() => (roles || [])?.map((role: any) => {
    return role.name;
  }), [roles]);  
  const countryId = sessionStorageServices.getItem(APPCONSTANTS.REGION_ID);
  useEffect(() => {
    sessionStorageServices.deleteItem(APPCONSTANTS.IS_KENYA);
    dispatch(clearIsKenya());
  }, [dispatch]);

  return (
    <div className='position-relative'>
      <div className='row'>
      {(rolesValue.includes(APPCONSTANTS.ROLES.EMR_REPORT_ADMIN.role) ||  rolesValue.includes(APPCONSTANTS.ROLES.EMR_REGION_ADMIN.role) ||
        rolesValue.includes(APPCONSTANTS.ROLES.EMR_ACCOUNT_ADMIN.role) || rolesValue.includes(APPCONSTANTS.ROLES.EMR_OU_ADMIN.role) ||
        rolesValue.includes(APPCONSTANTS.ROLES.EMR_SITE_ADMIN.role) || rolesValue.includes(APPCONSTANTS.ROLES.SYSTEM_ADMIN.role)) 
         &&
        <div className='col-3 width-22'  >
          <div className='cards'
          onClick={() => {
            APPCONSTANTS.KENYA_ID === sessionStorageServices.getItem(APPCONSTANTS.REGION_ID) ?
            sessionStorageServices.setItem(APPCONSTANTS.IS_KENYA, true)
            : sessionStorageServices.setItem(APPCONSTANTS.IS_KENYA, false);
            dispatch(fetchIsKenya());
          }}>
            <Link to={(role === APPCONSTANTS.ROLES.SYSTEM_ADMIN || role === APPCONSTANTS.ROLES.EMR_REPORT_ADMIN) ?
               PROTECTED_ROUTES.regionDashboard : PROTECTED_ROUTES.siteByRegion.replace(':regionId', countryId)} >
              <div className='row p-2'>
                <img src={ReportsLogo} alt='report-logo' width={60} height={70}/>
              </div>
              <div className='row card-text p-1'>
                <p>REPORTS</p>
              </div>
            </Link>
          </div>
        </div>}
        {/* { (rolesValue.includes(APPCONSTANTS.ROLES.EMR_QUICKSIGHT_ADMIN.role))
        &&
        <div className='col-3 width-22'>
          <div className='cards'>
            <Link to={PROTECTED_ROUTES.quickSightDashboard}>
              <div className='row p-2'>
                <img src={InsightsLogo} alt='insight-logo' width={60} height={70}/>
              </div>
              <div className='row card-text p-1'>
                <p>INSIGHTS</p>
              </div>
            </Link>
          </div>
        </div>
        } */}
      </div>
    </div>
  );
};

export default Home;
