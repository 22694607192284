import React, { useCallback, useRef } from 'react';
import { useEffect } from 'react';
import styles from './Checkbox.module.scss';

interface ICheckboxProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  name?: string;
  isResetFilterEnabled?: boolean;
  setResetFilterEnabled?: (data: boolean) => void;
}

const Checkbox = ({ label, name, isResetFilterEnabled, setResetFilterEnabled, ...inputProps }: ICheckboxProps) => {
  const checkboxRef = useRef<HTMLInputElement>(null);
  const onKeyPress = useCallback((e: React.KeyboardEvent) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.key === 'Enter') {
      checkboxRef.current?.click();
    }
  }, []);

  useEffect(() => {
    if (checkboxRef.current?.checked && isResetFilterEnabled) {
      checkboxRef.current?.click();
    }
    return () => { if (isResetFilterEnabled === false && setResetFilterEnabled) {setResetFilterEnabled(false); }};
  }, [isResetFilterEnabled, setResetFilterEnabled]);

  return (
    <label className={`d-inline-flex align-items-center ${styles.checkboxLabel}`}>
      <div className={styles.checkboxWrapper}>
        <input
          value={name}
          type='checkbox'
          name={name}
          {...inputProps}
          className={styles.checkbox}
          ref={checkboxRef}
          onKeyPress={onKeyPress}
        />
      </div>
      {<span>{label}</span>}
    </label>
  );
};

export default Checkbox;
