import React from 'react';
import { Field, Form } from 'react-final-form';
import crypto from 'crypto';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { Dispatch } from 'redux';
import { Link } from 'react-router-dom';

import logo from '../../assets/images/app-logo.svg';
import TextInput from '../../components/formFields/TextInput';
import { AppState } from '../../store/rootReducer';
import {
  composeValidators,
  validatePassword,
  isEmpty
} from '../../utils/validation';
import { error, info } from '../../utils/toastCenter';
import styles from './Authentication.module.scss';
import APPCONSTANTS from '../../constants/appConstants';
import {
  getUserName,
  resetPassword,
  createPasswordRequest
} from '../../store/user/actions';
import { PUBLIC_ROUTES } from '../../constants/route';
import commonPassword from '../../utils/Common_Passwords';
import Loader from '../../components/loader/Loader';
import ShowPasswordIcon from '../../assets/images/showPass.svg';
import HidePasswordIcon from '../../assets/images/hidePass.svg';
import CustomTooltip from '../../components/tooltip';

interface IDispatchProps {
  createPassword: ({
    email,
    password,
    token,
    successCB
  }: {
    email: string;
    password: string;
    token: string;
    successCB: () => void;
  }) => void;
  getUserName: (data: {
    token: string;
    successCB: () => void;
    failureCB: () => void;
  }) => void;
  resetPassword: (data: {
    email: string;
    password: string;
    token: string;
    successCB: () => void;
  }) => void;
}

interface IStateProps {
  loading: boolean;
  isPasswordSet: boolean;
  email: string;
  username: string;
}

interface IRouteProps extends RouteComponentProps<{ token: string }> { }

type Props = IRouteProps & IDispatchProps & IStateProps;

interface IResetPasswordState {
  isResetPassword: boolean;
  token: string;
  isShowPassword: boolean;
  isShowConfirmPassword: boolean;
}
class ResetPassword extends React.PureComponent<Props, IResetPasswordState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isResetPassword: false,
      token: '',
      isShowPassword: false,
      isShowConfirmPassword: false
    };
  }

  componentDidMount() {
    const url = this.props.history.location.search;
    const params = new URLSearchParams(url);
    const isResetPassword = Boolean(params.get('reset_password'));
    this.setState({ isResetPassword });
    let canRequest: boolean = true;
    if (isResetPassword) {
      const expiresValue = params.get('expires');
      const expiresTime = Number(expiresValue);
      if (expiresTime < new Date().getTime()) {
        canRequest = false;
        info('', APPCONSTANTS.LINK_EXPIRED);
        this.backToLogin();
      }
    }
    if (canRequest) {
      this.getUsername();
    }
  }

  validatePasswordMatch = (value: string, allValues: any) => {
    if (isEmpty(value)) {
      return APPCONSTANTS.ENTER_CONFIRM_PASSWORD;
    } else if (value !== allValues.password) {
      return APPCONSTANTS.CONFIRM_PASSWORD_SHOULD_MATCH;
    }
    return '';
  };

  backToLogin = () => {
    this.props.history.push({ pathname: PUBLIC_ROUTES.login });
  };

  getUsername = () => {
    const { token } = this.props.match.params;
    this.setState({ token });
    this.props.getUserName({ token, successCB: this.showToast, failureCB: this.failureCB });
  };

  showToast = () => {
    const { isResetPassword } = this.state;
    if (this.props.isPasswordSet && isResetPassword) {
      info('', APPCONSTANTS.PASSWORD_ALREADY_CHANGED);
      this.backToLogin();
    } else if (this.props.isPasswordSet) {
      info('', APPCONSTANTS.ACCOUNT_ALREADY_ACTIVATED);
      this.backToLogin();
    }
  };

  failureCB = () => {
    error(APPCONSTANTS.OOPS, APPCONSTANTS.PASSWORD_SET_FAILED);
  }

  onSubmitForm = (values: any) => {
    const email = values.username;
    const hmac = crypto.createHmac(APPCONSTANTS.HASH_ALGORITM, process.env.REACT_APP_PASSWORD_HASH_KEY as string);
    hmac.update(values.password);
    const password = hmac.digest('hex');
    const { token, isResetPassword } = this.state;
    if (isResetPassword) {
      this.props.resetPassword({
        email,
        password,
        token,
        successCB: this.backToLogin
      });
    } else {
      this.props.createPassword({
        email,
        password,
        token,
        successCB: this.backToLogin
      });
    }
  };

  checkUsername = (password: string) => {
    const { username } = this.props;
    if (isEmpty(password)) {
      return APPCONSTANTS.ENTER_PASSWORD;
    } else if (username.split('@')[0]?.toLowerCase() === password.toLowerCase()) {
      return APPCONSTANTS.PASSWORD_SHOULD_NOT_MATCH_ACC_NAME;
    } else if (commonPassword.includes(password)) {
      return APPCONSTANTS.COMMON_PASSWORDS_ARE_NOT_ALLOWED;
    }
  };

  setShowPassword = () => {
    this.setState((prevState) => ({
      isShowPassword: !prevState.isShowPassword
    }));
  };

  toggleShowConfirmPassword = () => {
    this.setState((prevState) => ({ isShowConfirmPassword: !prevState.isShowConfirmPassword }));
  };

  render() {
    const { username, loading } = this.props;
    const { isShowPassword, isShowConfirmPassword } = this.state;
    return (
      <div className={styles.loginPage}>
        {loading && <Loader />}
        <div className={styles.loginFormContainer}>
          <div className={`${styles.brand} text-center`}>
            <img src={logo} alt='Medtronics' />
          </div>
          <div className={`primary-title text-center ${styles.loginTitle}`}>
            Reset your password
          </div>
          <Form
            onSubmit={this.onSubmitForm}
            initialValues={{ username }}
            render={({ handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <div className={styles.togglePassword}>
                  <Field
                    name='password'
                    type={isShowPassword ? 'text' : 'password'}
                    validate={composeValidators(validatePassword, this.checkUsername)}
                    render={({ input, meta }) => (
                      <CustomTooltip title={APPCONSTANTS.PASSWORD_RULE}>
                        <TextInput
                          {...input}
                          label='New Password'
                          placeholder='Enter New Password'
                          error={(meta.touched && meta.error) || undefined}
                          className={styles.passwordBox}
                        />
                        <img
                          className={styles.eyeIcon}
                          title={isShowPassword ? 'Hide password' : 'Show password'}
                          src={isShowPassword ? ShowPasswordIcon : HidePasswordIcon}
                          onClick={this.setShowPassword}
                          alt={isShowPassword ? 'Hide password' : 'Show password'}
                        />
                      </CustomTooltip>
                    )}
                  />
                </div>
                <div className={styles.togglePassword}>
                  <Field
                    name='confirmPassword'
                    type={isShowConfirmPassword ? 'text' : 'password'}
                    validate={this.validatePasswordMatch}
                    render={({ input, meta }) => (
                      <>
                        <TextInput
                          {...input}
                          placeholder='Re-enter New Password'
                          label='Confirm New Password'
                          errorLabel=''
                          error={(meta.touched && meta.error) || undefined}
                          className={styles.passwordBox}
                        />
                        <img
                          className={styles.eyeIcon}
                          title={isShowConfirmPassword ? 'Hide password' : 'Show password'}
                          src={isShowConfirmPassword ? ShowPasswordIcon : HidePasswordIcon}
                          onClick={this.toggleShowConfirmPassword}
                          alt={isShowConfirmPassword ? 'Hide password' : 'Show password'}
                        />
                      </>
                    )}
                  />
                </div>
                <button type='submit' className='mt-2 btn primary-btn w-100'>
                  Submit
                </button>
              </form>
            )}
          />
          <div className={styles.backToLoginFooter} onClick={this.backToLogin}>
            <Link to={PUBLIC_ROUTES.login}>Go to login page</Link>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: AppState): IStateProps => ({
  loading: state.user.loading,
  isPasswordSet: state.user.isPasswordSet,
  email: state.user.email,
  username: state.user.username || ''
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  createPassword: ({
    email,
    password,
    token,
    successCB
  }: {
    email: string;
    password: string;
    token: string;
    successCB: () => void;
  }) => dispatch(createPasswordRequest({ email, password }, token, successCB)),
  getUserName: ({
    token,
    successCB,
    failureCB
  }: {
    token: string;
    successCB: () => void;
    failureCB: () => void;
  }) => dispatch(getUserName(token, successCB, failureCB)),
  resetPassword: (data: {
    email: string;
    password: string;
    token: string;
    successCB: () => void;
  }) => dispatch(resetPassword(data))
});

export const generatePassword = (password: string) => {
  const hmac = crypto.createHmac(APPCONSTANTS.HASH_ALGORITM, process.env.REACT_APP_PASSWORD_HASH_KEY as string);
  hmac.update(password);
  return hmac.digest('hex');
};

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
