import { useCallback, useEffect, useState } from 'react';
import ReactSelect, {
  ActionMeta,
  components,
  OnChangeValue,
  StylesConfig
} from 'react-select';

import DropdownIcon from './../../assets/images/dropdown.svg';
import CheckboxIcon from './../../assets/images/checkbox.svg';
import CheckboxEmptyIcon from './../../assets/images/checkbox-empty.svg';
import CheckboxDisabledIcon from './../../assets/images/checkbox-disabled.svg';

import './MulitiSelect.scss';

export interface IOption {
  value: string;
  label: string;
  disabled: boolean;
  assigned?: boolean;
}

interface ISelect {
  loading?: boolean;
  selectedValue: IOption[];
  label?: string;
  labelPlural?: string;
  labelIcon?: string;
  options: IOption[];
  handleFilterChange?: (props: any) => void;
  isCustomDropdownEnabled?: boolean;
  isCustomValueEnabled?: boolean;
  isCustomOptionEnabled?: boolean;
  isCustomMenuListEnabled?: boolean;
  customStyles?: StylesConfig<any>;
  customOnChange?: any;
  isApplyButtonNeeded?: boolean;
  multiSelectRef?: any;
  setShowError?: any;
}

export const checkSelectedValueChanged = (ary1: any, ary2: any) => {
  const initialValue = ary1?.map((data: IOption) => data?.value);
  const changedValue = ary2.map((data: IOption) => data?.value);
  return (
    initialValue.length === changedValue.length &&
    initialValue.every((value: any) => changedValue.includes(value))
  );
};

const MulitiSelect = ({
  loading = false,
  selectedValue,
  label = 'Site',
  labelPlural = 'Sites',
  labelIcon = '',
  options,
  handleFilterChange,
  isCustomDropdownEnabled = true,
  isCustomValueEnabled = true,
  isCustomOptionEnabled = true,
  isCustomMenuListEnabled = true,
  isApplyButtonNeeded = true,
  customStyles = {},
  multiSelectRef,
  setShowError,
  customOnChange
}: ISelect) => {
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [filterValue, setFilteredValue] = useState(selectedValue || []);

  useEffect(() => {
    setFilteredValue(selectedValue);
  }, [selectedValue]);

  const handleMenuClose = () => {
    const newlySelectedValue = multiSelectRef.current?.getValue();
    const isValueChanged = checkSelectedValueChanged(
      selectedValue,
      newlySelectedValue
    );
    if (isValueChanged) {
      handleFilterChange?.(newlySelectedValue);
    } else {
      setFilteredValue(selectedValue);
    }
    return setMenuIsOpen(false);
  };

  const onApplyBtnClick = (props: any) => {
    handleFilterChange?.(props.getValue());
    setMenuIsOpen(false);
  };

  const onSelectAllBtnClick = (props: any) => {
    const selectAllActive = props?.getValue()?.length === props?.options.length;
    if (selectAllActive) {
      props.setValue([props?.options.find((item: any) => item?.disabled)]);
    } else {
      props.setValue(props?.options);
    }
  };

  const CustomDropdownIcon = useCallback(
    (props: any) => {
      return (
        <div className='custom-dropdown d-flex align-items-center'>
          {loading ? (
            // <Spinner width={14} height={14} />
            <></>
          ) : (
            <img
              src={DropdownIcon}
              className='custom-dropdown-icon'
              alt='dropdown-icon'
              height={20}
            />
          )}
        </div>
      );
    },
    [loading]
  );

  const CustomValueLabel = useCallback(
    (props: any) => {
      const selectedCount = props.getValue()?.length;
      let displayText: string | React.ReactElement = '';
      if (loading) {
        displayText = '--';
      } else if (selectedCount === props.options.length) {
        displayText = `All ${labelPlural}`;
      } else if (selectedCount > 1) {
        displayText = `${selectedCount} ${labelPlural}`;
      } else if (selectedCount === 1) {
        displayText = `${selectedCount} ${label}`;
      } else {
        displayText = <span className='text-light-grey'>Select</span>;
      }
      return (
        <components.ValueContainer {...props}>
          <div className='dummy-container d-flex'>{props.children[1]}</div>
          <div className='custom-label d-flex align-items-center no-wrap'>
            {labelIcon ? (
              <img
                src={labelIcon}
                width={labelIcon || 16}
                height={16}
                alt='icon'
                className='custom-label-icon'
              />
            ) : null}
            <span className='custom-label-title'>{displayText}</span>
          </div>
        </components.ValueContainer>
      );
    },
    [label, labelPlural, labelIcon, loading]
  );

  const CustomOptionIcon = useCallback((props: any) => {
    return (
      <div>
        <components.Option {...props}>
          <div className='custom-menu'>
            <img
              src={
                props?.isDisabled
                  ? CheckboxDisabledIcon
                  : props?.isSelected
                  ? CheckboxIcon
                  : CheckboxEmptyIcon
              }
              alt=''
              className={`custom-menu-icon ${
                props?.isDisabled && 'menu-disabled'
              }`}
              height={16}
            />
            <label
              className={`custom-menu-label ${
                props?.isDisabled && 'menu-disabled'
              }`}
            >
              {props.label}
            </label>
          </div>
        </components.Option>
      </div>
    );
  }, []);

  const CustomMenuList = useCallback(
    (props: any) => {
      const isValueNotChangedOrEmpty =
        !props.getValue()?.length ||
        checkSelectedValueChanged(selectedValue, props.getValue());
      const selectAllActive =
        props?.getValue()?.length === props?.options.length;
      const toggleClass = 'custom-select__option--is-selected';
      return (
        <div ref={props.innerRef} {...props.innerProps}>
          <div className='menu-overlay'>
            <div className='menu-scroll'>
              <div
                className={`select-btn custom-select__option ${
                  selectAllActive && toggleClass
                }`}
                onClick={() => onSelectAllBtnClick(props)}
              >
                <div className='custom-menu'>
                  <img
                    src={selectAllActive ? CheckboxIcon : CheckboxEmptyIcon}
                    alt=''
                    className='custom-menu-icon'
                    height={16}
                  />
                  <label className='custom-menu-label --select-all'>
                    {selectAllActive ? 'DeSelect All' : 'Select All'}
                  </label>
                </div>
              </div>
              {props.children}
            </div>
            <div className='custom-confirm-btn'>
              {isApplyButtonNeeded && (
                <button
                  className={`btn btn-primary btn-sm ${
                    isValueNotChangedOrEmpty && 'disabled'
                  }`}
                  onClick={() => onApplyBtnClick(props)}
                >
                  Apply
                </button>
              )}
            </div>
          </div>
        </div>
      );
    },
    // eslint-disable-next-line
    []
  );

  const componentConfig: any = {};

  if (isCustomDropdownEnabled) {
    componentConfig.DropdownIndicator = CustomDropdownIcon;
  }
  if (isCustomValueEnabled) {
    componentConfig.ValueContainer = CustomValueLabel;
  }
  if (isCustomOptionEnabled) {
    componentConfig.Option = CustomOptionIcon;
  }
  if (isCustomMenuListEnabled) {
    componentConfig.MenuList = CustomMenuList;
  }

  const onChange = (
    newValue: OnChangeValue<any, any>,
    actionMeta: ActionMeta<any>
  ) => {
    if (customOnChange) {
      customOnChange({
        newValue,
        actionMeta,
        setShowError,
        filterValue,
        handleFilterChange,
        setFilteredValue
      });
    } else {
      handleFilterChange?.(newValue);
      setFilteredValue(newValue);
    }
  };

  return (
    <>
      <div className={menuIsOpen ? 'modal-backdrop-fade' : ''} />
      <ReactSelect
        styles={customStyles}
        placeholder='Select sites'
        ref={multiSelectRef}
        value={filterValue}
        isDisabled={loading}
        onChange={onChange}
        isOptionDisabled={(option) => option.disabled}
        options={options}
        blurInputOnSelect={false}
        isMulti={true}
        classNamePrefix='custom-select'
        closeMenuOnSelect={false}
        closeMenuOnScroll={false}
        hideSelectedOptions={false}
        isSearchable={!isCustomValueEnabled}
        isClearable={false}
        maxMenuHeight={200}
        captureMenuScroll={false}
        menuIsOpen={!loading && menuIsOpen}
        menuShouldBlockScroll={false}
        onMenuOpen={() => setMenuIsOpen(true)}
        onMenuClose={handleMenuClose}
        components={componentConfig}
        menuPlacement={'auto'}
      />
    </>
  );
};

export default MulitiSelect;
