import React, {  useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Loader from '../../components/loader/Loader';
import ERRORS from '../../constants/error';
import APPCONSTANTS from '../../constants/appConstants';
import { useLoadMorePagination } from '../../hooks/pagination';
import { fetchRegionsRequest, setRegionDetail } from '../../store/region/actions';
import {
  getRegionsCountSelector,
  getRegionsLoadingMoreSelector,
  getRegionsLoadingSelector,
  getRegionsSelector
} from '../../store/region/selectors';
import toastCenter from '../../utils/toastCenter';
import { Link } from 'react-router-dom';
import sessionStorageServices from '../../global/sessionStorageServices';

import styles from './Region.module.scss';
import { PROTECTED_ROUTES } from '../../constants/route';
import { clearIsKenya, fetchIsKenya } from '../../store/user/actions';

/**
 * Lists all the regions
 * Provides search feature
 * Provided the links to navigate to creation page of super admin and region
 * @returns {React.ReactElement}
 */
const Region = (): React.ReactElement => {
  const dispatch = useDispatch();
  const regions = useSelector(getRegionsSelector);
  const regionsCount = useSelector(getRegionsCountSelector);
  const loading = useSelector(getRegionsLoadingSelector);
  const loadingMore = useSelector(getRegionsLoadingMoreSelector);
  const { isLastPage, loadMore } = useLoadMorePagination({
    total: regionsCount,
    itemsPerPage: APPCONSTANTS.REGIONS_PER_PAGE,
    onLoadMore: ({ skip, limit, onFail }) => {
      dispatch(
        fetchRegionsRequest({
          skip,
          limit,
          isLoadMore: true,
          failureCb: (e) => {
            onFail();
            if (e.message === ERRORS.NETWORK_ERROR.message) {
              toastCenter.error(APPCONSTANTS.NETWORK_ERROR, APPCONSTANTS.CONNECTION_LOST);
            } else {
              toastCenter.error(APPCONSTANTS.OOPS, APPCONSTANTS.REGION_FETCH_ERROR);
            }
          }
        })
      );
    }
  });

  useEffect(() => {
    sessionStorageServices.deleteItem(APPCONSTANTS.IS_KENYA);
    dispatch(clearIsKenya());
    dispatch(
      fetchRegionsRequest({
        skip: 0,
        limit: APPCONSTANTS.REGIONS_PER_PAGE,
        failureCb: (e) => {
          if (e.message === ERRORS.NETWORK_ERROR.message) {
            toastCenter.error(APPCONSTANTS.NETWORK_ERROR, APPCONSTANTS.CONNECTION_LOST);
          } else {
            toastCenter.error(APPCONSTANTS.OOPS, APPCONSTANTS.REGION_FETCH_ERROR);
          }
        }
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const searchText = useRef<string>('');

  const regionParsedData = useMemo(
    () =>
      regions.map(({ name, id, code }) => ({
        title: name,
        name,
        code,
        onNavigate: () => {
          sessionStorageServices.setItem(APPCONSTANTS.REGION_ID, id);
          sessionStorageServices.setItem(APPCONSTANTS.COUNTRY_NAME, name);
          name === APPCONSTANTS.KENYA ? sessionStorageServices.setItem(APPCONSTANTS.IS_KENYA, true) : sessionStorageServices.deleteItem(APPCONSTANTS.IS_KENYA) 
          dispatch(fetchIsKenya());
          dispatch(setRegionDetail({ name, id }));     
        },
        route: PROTECTED_ROUTES.siteByRegion.replace(':regionId', id.toString())
      })),
    [regions, dispatch]
  );

  const loadRegionIcon = (name:string) => {
    try {
     return require(`../../assets/images/${name}.svg`).default;
    } catch (err) {
      return require(`../../assets/images/no-icon.svg`).default;
    }
  };

  const noSearchResultAvailable = Boolean(searchText.current && !regionParsedData.length);

  return (
    <div className={`position-relative ${styles.regionContainer}`}>
      <div className={styles.regionsList}>
        <div className='row'>
          {regionParsedData.map((data) => (
            <div className='col-3 p-1 width-22' key={data.name}>
              <div className='cards'>
                <Link 
                  to={data.route}
                  onClick={(event: React.MouseEvent) => {
                    data.onNavigate();
                  }}
                >
                <div className='row p-2'>
                  <img src={loadRegionIcon(data.name)} alt={`${data.name}_logo`} width={60} height={70}/>
                </div>
                <div className='row card-text p-1'>
                  <p>{data.name}</p>
                </div>
                </Link>
              </div>
            </div>
          ))}
          {noSearchResultAvailable && (
            <div className={`col-12 text-center mt-1 py-3dot75 ${styles.noData}`}>
              <div className='fw-bold highlight-text'>No regions available</div>
              <div className='subtle-color fs-dot875 lh-1dot25 mb-1'>Try changing the search keyword</div>
            </div>
          )}
          {!isLastPage && !loadingMore && (
            <div className='col-12 text-center mt-3dot75'>
              <button className={`btn primary-btn ${styles.loadMoreBtn}`} onClick={loadMore}>
                Load More<b className={styles.ellipsis}>...</b>
              </button>
            </div>
          )}
          {loadingMore || loading ? (
            <div className={loadingMore ? `${styles.loaderWrapper} mt-3dot75` : ''}>
              <Loader isFulllScreen={!loadingMore} />
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default Region;
